import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Orlov = () => {
  return (
    <Layout>
      <Head title="7orlov" />
      <h3>7orlov</h3>
      <p>
        <OutboundLink href="https://ello.co/7orlov">Ello</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15196607/ello-optimized-6b072089.jpg"
        alt="7orlov art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15370902/ello-optimized-886a3d5b.jpg"
        alt="7orlov art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15370899/ello-optimized-c343eb5d.jpg"
        alt="7orlov art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15329803/ello-optimized-f8da340e.jpg"
        alt="7orlov art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/5617859/ello-optimized-bcc162ce.jpg"
        alt="7orlov art"
      />
    </Layout>
  );
};

export default Orlov;
